try {
  const slider = new Siema({
    duration: 650,
    loop: true,
  });

  setInterval(() => slider.next(), 6000);
} catch (error) {
  // do nothing, there is no Siema slider on the page
}

try {
  baguetteBox.run('.gallery');
} catch (error) {
  // do nothing, there is no .gallery on the page
}

setInterval(() => $('.hero__button').toggleClass('tada'), 3000);
